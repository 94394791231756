import { TableCell, TableRow } from '@material-ui/core'
import { Loader } from '@percent/cause-dashboard/common/components'
import { Table } from '@percent/cause-dashboard/common/components/table/Table'
import styles from './UsersListTable.module.scss'
import { Badge, Menu } from '@percent/lemonade'
import { IamGetAccounts } from '@percent/cause-dashboard/services/types/iamResponses.types'
import { EditUserRoleModal } from './EditUserRoleModal/EditUserRoleModal'
import { useAuthState } from '@percent/cause-dashboard/common/hooks'
import { usePermissionCheck } from '@percent/cause-dashboard/common/hooks/usePermissionCheck/usePermissionCheck'
import { useTranslation } from 'react-i18next'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useQuery } from '@percent/cause-dashboard/common/hooks/useQuery/useQuery'
import React, { useState } from 'react'
import { RemoveUserModal } from './RemoveUserModal/RemoveUserModal'

interface UsersListTableProps {
  isLoading: boolean
  data: IamGetAccounts[] | null
  totalResultCount: number
  onNextPageClick: (() => void) | null
  onPreviousPageClick: (() => void) | null
  onRefetchData: () => void
}

export function UsersListTable({
  data,
  isLoading,
  totalResultCount,
  onNextPageClick: nextPage,
  onPreviousPageClick: previousPage,
  onRefetchData
}: Readonly<UsersListTableProps>) {
  const { t } = useTranslation()
  const [currentlUserIdToEdit, setCurrentlUserIdToEdit] = useState<string>('')
  const [currentUserIdToRemove, setCurrentUserIdToRemove] = useState<string>('')

  const { iamService } = useServices()
  const [{ data: rolesData }] = useQuery(iamService.getRoles, {})
  const allRoles = rolesData?.data?.data?.roles

  const {
    authState: { user: currentUser }
  } = useAuthState()
  const { userCan } = usePermissionCheck()

  const canPerformActions = userCan('organization_users:write')

  if (isLoading) {
    return <Loader />
  }

  const handleRoleChanged = (refetch: boolean) => {
    setCurrentlUserIdToEdit('')
    if (refetch) {
      onRefetchData()
    }
  }

  const handleUserDeleted = (refetch: boolean) => {
    setCurrentUserIdToRemove('')
    if (refetch) {
      onRefetchData()
    }
  }

  const columns = [
    { id: 'typography.userManagement.email', isSortable: false, props: { width: '80%' } },
    { id: 'typography.userManagement.role', isSortable: false, props: { width: '20%' } },
    ...(canPerformActions
      ? [{ id: 'typography.userManagement.actions', isSortable: false, props: { width: 'auto' } }]
      : [])
  ]

  return (
    <Table
      data={data}
      isLoading={isLoading}
      totalResults={totalResultCount || 0}
      previousPage={previousPage}
      nextPage={nextPage}
      columns={columns}
      emptyTableText={t('table.noUsers')}
      orderBy=""
    >
      <div data-testid={'users-table'} />

      {data?.map(({ id, email, role }) => {
        const menuSections = [
          {
            items: [
              {
                key: 'edit',
                label: t('typography.userManagement.edit'),
                shouldBeDisabled: false,
                onClick: () => setCurrentlUserIdToEdit(id)
              },
              {
                key: 'remove',
                label: t('typography.userManagement.remove'),
                shouldBeDisabled: false,
                onClick: () => setCurrentUserIdToRemove(id)
              }
            ]
          }
        ]

        return (
          <React.Fragment key={`permissions-list-${id}`}>
            <EditUserRoleModal
              accountId={id}
              email={email}
              role={role}
              open={currentlUserIdToEdit === id}
              close={handleRoleChanged}
              roles={allRoles ?? []}
            />
            <RemoveUserModal
              accountId={id}
              email={email}
              open={currentUserIdToRemove === id}
              close={handleUserDeleted}
            />
            <TableRow key={`permissions-list-${id}`} data-testid={`row-${id}`}>
              <TableCell>
                <div className={styles.cellWrapper}>
                  <span>{email}</span>
                </div>
              </TableCell>
              <TableCell data-testid={`row-${id}-role-${role.displayName}`}>
                <Badge variant="default" testId="role-badge">
                  {role.displayName}
                </Badge>
              </TableCell>
              {currentUser?.id !== id && canPerformActions && (
                <TableCell data-testid={`row-${id}-action`}>
                  <Menu
                    title={t('typography.userManagement.more')}
                    sections={menuSections}
                    onSelect={(key: string) => {
                      if (key === 'edit') {
                        setCurrentlUserIdToEdit(id)
                      } else if (key === 'remove') {
                        setCurrentUserIdToRemove(id)
                      }
                    }}
                  />
                </TableCell>
              )}
            </TableRow>
          </React.Fragment>
        )
      })}
    </Table>
  )
}
